<template>
  <div :class="isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <h3 style="text-align: center" class="font-semibold text-base text-2xl">
        Computer Based Tests
      </h3>
      <i>Note: Your timer starts immediately you click start exam, and won't stop counting even if you refresh page or close browser.
        Make sure you are ready to take a quiz before starting. The duration of the quiz is stated and you can only see your score after the timer runs out. <i style="color:brown"> Remember to always submit every answer before moving to the next question</i>. Be sure to review your answers before the timer runs out.</i>
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">

        </div>
        <div
            class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
        </div>
      </div>
    </div>
    <div v-if="!review && !takingExam">
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <div v-if="!examIsLoading" class="block w-full overflow-x-auto">
          <table class="items-center w-full bg-transparent border-collapse">
            <thead>
            <tr>
              <th
                  class="px-6 bg-blueGray-50 font-bold text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Title
              </th>
              <th
                  class="px-6 bg-blueGray-50 font-bold text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Subject
              </th>
              <th
                  class="px-6 bg-blueGray-50 font-bold text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Type
              </th>
              <th
                  class="px-6 bg-blueGray-50 font-bold text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Expires
              </th>
              <th
                  class="px-6 bg-blueGray-50 font-bold text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Duration
              </th>
              <th
                  class="px-6 bg-blueGray-50 font-bold text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Class
              </th>
              <th
                  class="px-6 bg-blueGray-50 font-bold text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Score
              </th>
              <th
                  class="px-6 bg-blueGray-50 font-bold text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="exam in exams.results" :key="exam.id">
              <th v-if="exam.title"
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                {{ exam.title }}
              </th>
              <td v-if="exam.subject.name"
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ exam.subject.name }}
              </td>
              <td v-if="exam.subject.name"
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ exam.type }}
              </td>
              <td v-if="exam.expiration_date && exam.expiration_time"
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ moment(exam.expiration_date).format("DD/MM/YYYY") }},  {{ moment(exam.expiration_time, "HH:mm:ss").format("h:mm A") }}
              </td>
              <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ exam.exam_time }} Mins
              </td>
              <td
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ exam.student_class }}
              </td>

              <td v-if="exam.studentExamStatus.score || exam.expired"
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <p v-if="exam.studentExamStatus.completed || exam.expired">{{ exam.studentExamStatus.score }}/{{ exam.overallScore }}</p>
              </td>
              <td v-if="exam.studentExamStatus.completed !== 'Not Done'"
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <button
                    v-on:click="reviewExam(exam.id)"
                    v-if="exam.studentExamStatus.completed || exam.expired"
                    class="btn text-emerald-500 bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    :style="`background-color: brown; color: white`"
                >
                  Review
                </button>
                <button
                    v-on:click="callStart(exam.id)"
                    v-else-if="!exam.studentExamStatus.completed && exam.studentExamStatus.id == null && !exam.expired"
                    class="btn text-emerald-500 bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    :style="`background-color: brown; color: white`"
                >
                  {{ `${startingExam ? 'Starting...': 'Start'}`}}
                </button>
                <button
                    v-on:click="takeExam(exam.id)"
                    v-else-if="!exam.studentExamStatus.completed && exam.studentExamStatus.id !== null && !exam.expired"
                    class="btn text-emerald-500 bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    :style="`background-color: brown; color: white`"
                >
                  {{ `${startingExam ? 'Resuming...': 'Continue'}`}}
                </button>
              </td>
            </tr>

            </tbody>
          </table>
          <i v-if="error" class="text-1xl" style="color: red">CBT id error, click back and try again.</i>
        </div>
        <div v-else class="text-2xl content-center justify-center" style="text-align: center">
          <br/>
          <font-awesome-icon icon="spinner" spin class="text-4xl"/>
          <br/>
          <br/>
          <br/>
        </div>
        <div v-if="exams.total_exams > 10" class="mb-3 mt-3">
          <pagination
              :totalItems="exams.total_exams"
              :pageSize="exams.countItemsOnPage"
              :current-page="current_page_exams"
              @update:current-page="updateCurrentPageExams"
          />
        </div>
      </div>
    </div>
    <transition name="fade" appear>
      <div v-if="review && !isLoading">
        <h3 style="text-align: center;" class="font-semibold text-base text-blueGray-700 centered mt-2">{{ question.results[0].exam }} (Review) </h3>

        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="question" :class="[isDarkMode ? 'dark-bg text-white' : 'bg-gray-100 text-gray-900']">
            <div v-if="question.results[0]?.diagram" class="w-full mb-4">
    <img 
      :src="question.results[0].diagram" 
      alt="Question Diagram" 
      class="max-w-full h-auto rounded-lg shadow-lg mx-auto"
    />
  </div>
            <h2 class="text-xl font-semibold">{{ current_page }}. {{ question.results[0].quiz }}</h2>
            <div v-if="question.results" class="options mt-4">
              <label class="flex items-center">
                <span>Selected: {{ studentOption }}</span>
              </label>
              <label class="flex items-center">
                <span>Answer: {{ answer }}</span>
              </label>
              <font-awesome-icon v-if="studentOption === answer" icon="check" class="text-teal-500 ml-2" />
              <font-awesome-icon v-else icon="times" class="text-red-500 ml-2" />
            </div>

          </div>
          <button
              @click="takingExam = false, review = false"
              class="btn text-emerald-500 mt-3 bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              :style="`background-color: brown; color: white`"
          >
            esc
          </button>
        </div>
        <div class="mb-3 mt-3">
          <pagination
              :totalItems="question.total_questions"
              :pageSize="question.countItemsOnPage"
              :current-page="current_page"
              @update:current-page="updateCurrentPage"
          />
        </div>
      </div>
      <div v-else-if="review && isLoading" class="px-6">
        <div class="w-full px-4 flex justify-center">

          <font-awesome-icon icon="spinner" spin class="text-3xl"/>

        </div>
      </div>
    </transition>
    <transition name="pop" appear>
      <div v-if="takingExam && !isLoading">
  <h3 style="text-align: center;" class="font-semibold text-base text-blueGray-700 centered mt-2">{{ question.results[0]?.exam }} <p :style="`color: ${timeStyle}`">Time left: {{ timeLeft }}</p></h3>
  
  <div class="rounded-t mb-0 px-4 py-3 border-0 ">
    <div class="question" :class="[isDarkMode ? 'dark-bg text-white' : 'bg-gray-100 text-gray-900']">
      <div v-if="question.results[0]?.diagram" class="w-full mb-4">
    <img 
      :src="question.results[0].diagram" 
      alt="Question Diagram" 
      class="max-w-full h-auto rounded-lg shadow-lg mx-auto"
    />
  </div>
      <h2 class="text-xl font-semibold">{{ current_page }}. {{ question.results[0]?.quiz }}</h2>
      <div v-if="question.results && question.results[0]?.question_type === 'MULTIPLE'" class="options mt-4">
        <label v-for="(option, index) in question.results[0].answers" :key="index" class="flex items-center">
          <input type="radio" :value="option" v-model="selectedOption" class="mr-2">
          <span>{{ option.quote }}</span>
        </label>
      </div>
      <div v-else-if="question.results && question.results[0]?.question_type === 'SINGLE'" class="options mt-4">
        <label class="flex items-center">
          <p>Your Answer:</p>
        </label>
        <input type="text" v-model="selectedOption" class="border-0 mb-4 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150">
      </div>
      <button
        v-on:click="submitOption()"
                v-if="!submiting"
                class="btn mt-4 text-emerald-500 bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                :style="`background-color: brown; color: white`"
            >
              submit
            </button>
            <button
                v-else
                class="btn text-emerald-500 bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                :style="`background-color: brown; color: white`"
            >
              <i class="fas fa-spinner fa-spin text-emerald-500"></i>
            </button>
    </div>
    <i v-if="finalQuestion" class="text-black">Last Question</i>
    <div class="mb-3 mt-3">
    <pagination
      :totalItems="question.total_questions"
      :pageSize="question.countItemsOnPage"
      :current-page="current_page"
      @update:current-page="updateCurrentPage"
    /> 
    </div>
    </div>
    </div>
      <div v-else-if="takingExam && isLoading" class="px-6">
        <div class="w-full px-4 flex justify-center">

          <font-awesome-icon icon="spinner" spin class="text-3xl"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const axios = require("@/axios");
import Pagination from "./PaginationPage.vue"
import moment from 'moment'
// import Timer from "./Timer.vue"
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
  props: {
    studentSlug: {
      type: String,
      required: true
    },
    //   options: {
    //     type: Array,
    //     required: true
    //   }
  },
  components:{
    Pagination,
    // Timer
  },
  watch: {
    parentVariable(){
      this.review = false;
      this.takingExam = false;
    },
    current_page(){
      this.finalQuestion = this.current_page >= this.question.last_page;
    },

  },
  created(){
    this.moment = moment;
  },
  data() {
    return {
      parentVariable: 1,
      examID: null,
      startTime: null, // Replace with your start timestamp value
      duration: null,
      examIsLoading: false,
      error: false,
      isLoading: false,
      startingExam: false, finalQuestion: false,
      exams: {
        "last_page": 1,
        "countItemsOnPage": 10,
        "current_page": 1,
        "next_page": "",
        "previous_page": "",
        "total_exams": 2,
        "results":[]
      },
      timer: null,
      answer: null,
      studentOption: null,
      timeLeft: '',
      timeStyle: '',
      submiting: false,
      review: false,
      takingExam: false,
      submited: false,
      selectedOption: null,
      current_page: 1,
      current_page_exams: 1,
      timerSett: false,
      question: {
        "last_page": 2,
        "countItemsOnPage": 1,
        "current_page": 1,
        "next_page": "http://localhost:8000/examination/questions/funmi-martins/1/?page=2",
        "previous_page": "",
        "total_questions": "",
        "results": [
          {"exam": {}, "quiz": "", }
        ]
      }
    };
  },

  computed:{
    ...mapGetters(['isDarkMode']),
    isAdmin(){
      return this.$store.state.userDetails.is_admin
    },
    isSchoolAdmin(){
      return this.$store.state.userDetails.is_school_admin
    },
    isStaff(){
      return this.$store.state.userDetails.is_school_staff
    },
    isParent(){
      return this.$store.state.userDetails.is_parent
    },
  },
  mounted(){
    this.getExams()
  },

  methods: {
    callStart(id) {
  const isDarkMode = this.isDarkMode; // Check dark mode state
  this.$swal({
    title: 'Start Test?',
    text: "You are about to start this test. Your time starts immediately you click yes and " +
      "does not stop till it runs out, even if you leave or refresh the page. Good Luck!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: isDarkMode ? '#1a73e8' : '#3085d6',  // Dark mode color for confirm button
    cancelButtonColor: isDarkMode ? '#ff1744' : '#d33',       // Dark mode color for cancel button
    confirmButtonText: 'Start',
    cancelButtonText: 'Not Ready',
    allowOutsideClick: false,
    background: isDarkMode ? '#333' : '#fff',  // Set background color for dark mode
    color: isDarkMode ? '#fff' : '#000',       // Set text color for dark mode
  }).then((result) => {
    if (result.isConfirmed) {
      this.takeExam(id);
    }
  });
},

setTimer() {
  const examStartTime = this.startTime; // Replace with actual exam start time
  const duration = this.duration; // Exam duration in minutes

  const [hours, minutes, seconds] = examStartTime.split(':');
  const startTime = new Date();
  startTime.setHours(hours);
  startTime.setMinutes(minutes);
  startTime.setSeconds(seconds);

  const endTime = new Date(startTime.getTime() + duration * 60000);

  const countdownInterval = setInterval(() => {
    const currentTime = new Date();
    const remainingTime = Math.max(0, endTime.getTime() - currentTime.getTime());
    const remainingSeconds = Math.floor(remainingTime / 1000);

    // console.log(`Remaining Seconds: ${remainingSeconds}`); // Debugging output

    if (remainingSeconds < 300 && remainingSeconds > 120) {
      this.timeStyle = "orange";
    }

    if (remainingSeconds < 120) {
      this.timeStyle = "red";
    }

    if (remainingSeconds === 0) {
      console.log("Timer ended");
      clearInterval(countdownInterval);
      this.takingExam = false;
      this.review = false;

      this.$swal({
        position: 'center',
        icon: 'info',
        title: 'Time Up!',
        timer: 10000,
        background: this.isDarkMode ? '#333' : '#fff',
        color: this.isDarkMode ? '#fff' : '#000',
        iconColor: this.isDarkMode ? '#fff' : '#000',
      });

      this.logOut();
    }

    const minutesLeft = Math.floor(remainingTime / 60000);
    const secondsLeft = Math.floor((remainingTime % 60000) / 1000);
    this.timeLeft = `${minutesLeft}:${secondsLeft.toString().padStart(2, '0')}`;
  }, 1000);
},

    reviewExam(id){
      this.current_page = 1
      this.examID = id
      this.getQuestion()
      this.takingExam = false;
      this.review = true;
    },
    takeExam(id){
      this.current_page = 1
      this.examID = id
      this.timerSett = true;
      this.startExamTimer(id)
      this.review = false;
      this.takingExam = true;
    },
    async startExamTimer(id){
      const authe = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      try {
        this.startingExam = true;
        const response = await axios.get(`/examination/start-exam/${this.studentSlug}/${id}/`, authe)
        if(response){
          await this.getQuestion()
        }
      } catch (error) {
        console.log(error)
      }
    },
    updateCurrentPage(page) {
      this.current_page = page;
      // this.current_page = event;
      this.getQuestion()
    },
    updateCurrentPageExams(page) {
      this.current_page_exams = page;
      // this.current_page = event;
      this.getExams()
    },
    async getQuestion(){
        const authe = {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        };
        try {
          this.isLoading = true;
          const response = await axios.get(`/examination/questions/${this.studentSlug}/${this.examID}/?page=${this.current_page}`, authe);
          if(response){

            this.submited = false;
            this.startingExam = false;
            this.question = response.data;
            if(this.question.results[0].student_pick.id){
              this.selectedOption = this.question.results[0].answers.find((answer) => answer.id === this.question.results[0].student_pick.id);
            } else {
              this.selectedOption = null;
            }
            if(this.question.results[0].question_type === "SINGLE"){
             this.selectedOption = this.question.results[0].student_pick.quote;
            }

            this.startTime = this.question.results[0].studentExamStatus.startTime;
            this.duration = this.question.results[0].studentExamStatus.duration;
            this.studentOption = this.question.results[0].student_pick.quote;
            this.answer = this.question.results[0].question_type === 'MULTIPLE' ? this.question.results[0].answers.find((answer) => answer.is_correct).quote : this.question.results[0].answers[0];
            this.isLoading = false;
            if(this.timerSett){
              this.setTimer();
            }
            this.timerSett = false;
          }
        } catch (error) {
          console.log(error);
        }
      },

    async getExams(){
      const authe = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      try {
        this.examIsLoading = true;
        const response = await axios.get(`/examination/cbt/${this.studentSlug}/exams/?page=${this.current_page_exams}`, authe)
        if(response){
          this.exams = response.data
          this.examIsLoading = false;
        }
      } catch (error) {
        console.log(error)
        this.examIsLoading = false;
        this.error = true;
      }
    },

    async logOut(){
      const authe = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      const data ={
        "refresh": localStorage.getItem("refresh")
      }
      try {
        this.logging = true;
        const response = await axios.post("auth/logout/", data, authe)
        if (response) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("user");
          localStorage.removeItem("userLoggedIn");
          localStorage.removeItem("userIsAdmin"); // Set the admin status
          localStorage.removeItem("id");
          this.logging = false;
          this.$router.push({name: 'Login'});
        }
      }
      catch (e) {
        console.log(e)
        this.logging = false;
      }
    },
    //     calculateTimeRemaining() {
    //   const currentTimestamp = Date.now();
    //   const jj = new Date().getTime()
    //   const endTime = jj + this.durationMinutes * 60 * 1000;
    //   const timeDifference = endTime - currentTimestamp;

    //   if (timeDifference <= 0) {
    //     this.timeRemaining = "Expired";
    //   } else {
    //     const seconds = Math.floor((timeDifference / 1000) % 60);
    //     const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    //     const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    //     // const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    //     this.timeRemaining = `${hours}h ${minutes}m ${seconds}s`;
    //   }
    // },
      async submitOption(){
        const authe = {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        };
        let data = {
          answerId: this.question.results[0]?.question_type === 'MULTIPLE' ? this.selectedOption.id : this.selectedOption
        };
        this.submiting = true;
        try {
          const response = await axios.post(`/examination/post-option/${this.studentSlug}/${this.question.results[0].id}/`, data, authe);
          if(response){
            this.submiting = false;
            // Move to the next question if there is a next page
            if(this.question.next_page) {
              this.current_page++;
              await this.getQuestion();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }

  }
});
</script>

<style scoped>
.question {
  padding: 1rem;
}

.options label {
  margin-bottom: 0.5rem;
}

.options input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  outline: none;
  border: 2px solid #4a5568;
  transition: border-color 0.2s ease;
  cursor: pointer;
}

.options input[type="radio"]:checked {
  border-color: #4299e1;
  background-color: #4299e1;
}

.options span {
  margin-left: 0.5rem;
}
</style>
